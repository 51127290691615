import React from "react"
import Layout from "../components/layouts"

export default class ContactPage extends React.Component {
    state = {
        name: "",
        email: "",
        msg: "",
        field: "",
        nameError: false,
        emailError: false,
        msgError: false,
    }
    validateEmail = email => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    handleInputChange = event => {
        const target = event.target
        const value = target.value
        const name = target.name
        this.setState({
            [name]: value,
        })
        if (value.length > 2) {
            if (name === 'email') {
                const isValid = !this.validateEmail(value);
                this.setState({
                    emailError: isValid
                })
            } else {
                this.setState({
                    [`${name}Error`]: false
                })
            }
        } else {
            this.setState({
                [`${name}Error`]: true
            })
        }
    }
    handleSubmit = event => {
        event.preventDefault()
        const hasName = event.target.name.value > 2 || false;
        const hasEmail = (event.target.email.value > 2 && !this.state.emailError) || false;
        const hasMessage = event.target.msg.value > 2 || false;
        const isBot = event.target['bot-field'].value || false;
        if (hasName && hasEmail && hasMessage && !isBot) {
            event.target.submit()
        }
    }
    render() {
        return (
            <Layout>
                <div className="contact">
                    <h2>Contactați-ne</h2>
                    <div className="wrapper">
                        <form name="contact" method="post" action="/trimis/" data-netlify="true" data-netlify-honeypot="bot-field" onSubmit={this.handleSubmit}>
                            <input type="hidden" name="form-name" value="contact" />
                            <p hidden>
                                <label>
                                    Don’t fill this out: <input name="bot-field" value={this.state.field} onChange={this.handleInputChange} />
                                </label>
                            </p>
                            <div className="field">
                                <input type="text" id="name" name="name" placeholder="Cum vă numiți?" required value={this.state.name} onChange={this.handleInputChange} />
                                <label htmlFor="name" style={this.state.nameError ? {borderColor: "red"} : {}}>Nume</label>
                            </div>
                            <div className="field">
                                <input type="text" id="email" name="email" placeholder="nume@domeniu.tld" required value={this.state.email} onChange={this.handleInputChange} />
                                <label htmlFor="email" style={this.state.emailError ? {borderColor: "red"} : {}}>E-Mail</label>
                            </div>
                            <div className="field">
                                <textarea id="msg" rows="4" name="msg" placeholder="Mesajul dvs..." required value={this.state.msg} onChange={this.handleInputChange}></textarea>
                                <label htmlFor="msg" style={this.state.msgError ? {borderColor: "red"} : {}}>Mesaj</label>
                            </div>
                            <input className="button" type="submit" value="Trimite" />
                        </form>
                    </div>
                </div>
            </Layout>
        )
    }
}
